import { INetworkConfig, INetworkTokenConfig } from "config/config";
import { useQueries, useQuery } from "react-query";
import { BigNumber, ethers } from "ethers";

import LiquidityPoolAbi from "abis/LiquidityPool.json";
import ERC20 from "abis/ERC20.json";
import LiquidityProvidersAbi from "abis/LiquidityProviders.json";

async function getSuppliedLiquidity(
  networkConfig: INetworkConfig,
  tokenConfig: INetworkTokenConfig
) {
  const provider = new ethers.providers.JsonRpcProvider(networkConfig.rpcUrl);
  const liquidityProvidersContract = new ethers.Contract(
    networkConfig.liquidityProvidersContractAddress,
    LiquidityProvidersAbi,
    provider
  );

  const suppliedLiquidityRaw =
    await liquidityProvidersContract.getSuppliedLiquidityByToken(
      tokenConfig.contractAddress
    );

  const suppliedLiquidity = parseFloat(
    ethers.utils.formatUnits(suppliedLiquidityRaw, tokenConfig.decimals)
  ).toFixed(2);
  return suppliedLiquidity;
}

async function getAvailableLiquidity(
  networkConfig: INetworkConfig,
  tokenConfig: INetworkTokenConfig
) {
  const provider = new ethers.providers.JsonRpcProvider(networkConfig.rpcUrl);

  const liquidityPoolContract = new ethers.Contract(
    networkConfig.liquidityPoolContractAddress,
    LiquidityPoolAbi,
    provider
  );

  const incentivePoolBalance: BigNumber =
    await liquidityPoolContract.incentivePool(tokenConfig.contractAddress);
  const accumulatedGasFee: BigNumber =
    await liquidityPoolContract.gasFeeAccumulatedByToken(
      tokenConfig.contractAddress
    );

  if (
    tokenConfig.contractAddress !== "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
  ) {
    const erc20Contract = new ethers.Contract(
      tokenConfig.contractAddress,
      ERC20,
      provider
    );
    const availableLiquidity = await erc20Contract
      .balanceOf(networkConfig.liquidityPoolContractAddress)
      .then((res: BigNumber) =>
        res.sub(incentivePoolBalance).sub(accumulatedGasFee)
      )
      .then((res: BigNumber) => {
        return ethers.utils.formatUnits(res, tokenConfig.decimals);
      });
    return availableLiquidity;
  }

  const availableLiquidity = await provider
    .getBalance(networkConfig.liquidityPoolContractAddress)
    .then((res: BigNumber) =>
      res.sub(incentivePoolBalance).sub(accumulatedGasFee)
    )
    .then((res: BigNumber) => {
      return ethers.utils.formatUnits(res, tokenConfig.decimals);
    });

  return availableLiquidity;
}

export default function useNetworkTokensLiquidityQuery(
  networkConfig: INetworkConfig
) {
  return useQuery(["liquidity", networkConfig.networkName], async () => {
    return await Promise.all(
      networkConfig.tokens.map(async (tokenConfig) => {
        const [suppliedLiquidity, availableLiquidity] = await Promise.all([
          await getSuppliedLiquidity(networkConfig, tokenConfig),
          await getAvailableLiquidity(networkConfig, tokenConfig),
        ]);
        return {
          tokenSymbol: tokenConfig.symbol,
          tokenContractAddress: tokenConfig.contractAddress,
          suppliedLiquidity,
          availableLiquidity,
        };
      })
    );
  });
}
